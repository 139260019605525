<template>
    <div class="fanClubPage">
        <div class="navbar">
            <div @click="$router.go(-1)">
                <svg-icon iconClass="leftArrow" class="leftArrow"></svg-icon>
            </div>
            <div>我的粉丝团</div>
            <div class="rightItem"></div>
        </div>
        <div class="body">
            <Pullrefresh @onLoad="onLoad" :loading="loading" :finished="finished" @onRefresh="onRefresh"
                :refreshing="refreshing" :firstLoading="firstLoading" :isNoData="isNoData" :isHigehtMax="true">
                <router-link tag="div" :to="`bloggerDetails?id=${item.id}`" class="card" v-for="(item, index) in fansGroupList" :key="index">
                   <ImgDecypt :src="item.avatar" round class="avatar" />
                    <div class="info">
                        <p class="name">{{item.name}}</p>
                        <p class="fanNum">粉丝数量：{{item.members | watchCount}}</p>
                    </div>
                </router-link>
            </Pullrefresh>
        </div>
    </div>
</template>

<script>
    import Pullrefresh from "@/views/widget/PullRefresh.vue";
    import { queryFansgroup } from '@/api/mine/fanClub.js'
    export default {
        components: {
            Pullrefresh,
        },
        data() {
            return {
                fansGroupList: [],
                pageNum: 1,
                pageSize: 10,
                loading: false,
                finished: false,
                refreshing: false,
                isNoData: false,
                firstLoading: true, //是否第一次加载
            }
        },
        created() {
            this.getData();
        },
        methods: {
            //上滑加载
            onLoad() {
                this.pageNum++;
                this.getData();
            },
            //刷新
            onRefresh() {
                this.refreshing = true;
                this.finished = false;
                this.pageNum = 1;
                this.fansGroupList = [];
                this.getData();
            },
            //获取数据
            async getData() {
                this.loading = true;
                let req = {
                    pageSize: this.pageSize,
                    pageNum: this.pageNum,
                }
                let ret = await this.$Api(queryFansgroup, req);

                this.loading = false;
                this.refreshing = false;
                this.firstLoading = false;

                if (ret && ret.code == 200) {
                    let list = ret.data.list;
                    this.fansGroupList = this.fansGroupList.concat(list);
                    // if (this.fansGroupList.length > 0) {
                    //     this.author = this.mediaInfos[0].author;
                    // }

                    if (list.length == 0 && this.pageNum == 1) {
                        this.isNoData = true;
                        return;
                    }
                    if (list.length < this.pageSize) {
                        this.finished = true;
                    }
                }
            },
        }
    }
</script>

<style lang="scss" scoped>
    .fanClubPage {
        .navbar {
            padding: 0 16px;
            box-sizing: border-box;
            height: 52px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: $white1;
            font-size: 16px;
            font-weight: 600;
            background: $vermillion;

            .rightItem {
                width: 25px;
            }
        }

        .body {
            padding: 0 16px;
            height: calc(100vh - 44px);
            background: $whiteThree;
            padding-top: 20px;

            .card {
                width: 100%;
                height: 55px;
                margin-bottom: 14px;
                display: flex;
                padding: 6px 0 6px 17px;
                box-sizing: border-box;
                background: url('../../../assets/png/fan_bg.png');
                background-size: 100% 100%;
                color: $white1;

                .avatar {
                    width: 40px;
                    height: 40px;
                    background-image: url('https://www.vm888.com/d/file/QQ/QQtouxiang/2020-02-28/aaaacb9f550267d494e257abd85b3ccb.jpg');
                    background-size: 100% 100%;
                    border-radius: 50%;
                    margin-right: 22px;
                }

                .info {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;

                    .name {
                        font-size: 14px;
                    }

                    .fanNum {
                        height: 18px;
                        line-height: 18px;
                        padding: 0 11px;
                        font-size: 10px;
                        border-radius: 10px;
                        background: rgba(255, 255, 255, 0.6)
                    }
                }
            }
        }
    }
</style>